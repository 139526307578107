
import { UserHome } from "./UserHome";

import UserNavbar from "./UserNavbar";
import UserLogin from "./UserLogin";
export const Home = () => {

  return (
    <>
   <UserLogin/>

    </>
  );
};